<template>
  <div class="container">
    <div class="d-flex justify-content-center h-100 mt-5">
      <div class="card">
        <div class="card-header">
          <h3>Sign In</h3>
        </div>
        <div class="card-body">
          <form @submit.prevent="submit">
            <div class="mb-3 input-group">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['fas', 'user']" />
                </span>
              <input v-model="form.email" type="text" class="form-control" @keyup.enter="submit" placeholder="E-Mail">
            </div>
            <div class="mb-3 input-group">
                <span class="input-group-text">
                  <font-awesome-icon :icon="['fas', 'key']" />
                </span>
              <input v-model="form.password" type="password" class="form-control" @keyup.enter="submit" placeholder="Password">
            </div>
            <div v-if="showError" class="alert alert-danger" role="alert">
              Username or Password is incorrect
            </div>
            <div class="form-group">
              <input type="submit" value="Login" class="btn float-right login_btn">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showError: false
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const LoginData = new FormData();
      LoginData.append("email", this.form.email);
      LoginData.append("password", this.form.password);
      try {
        await this.LogIn(LoginData);
        this.$router.push("/");
        this.showError = false
      } catch (error) {
        this.showError = true
      }
    },
  },
};
</script>

<style scoped>
/* Made with love by Mutiullah Samim*/

.container{
  height: 100%;
  align-content: center;
}

.card {
  height: 370px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0,0,0,0.5) !important;
}

.card-header h3 {
  color: white;
}

.input-group-text {
  background-color: #FFC312;
  color: black;
  border:0 !important;
}

input:focus {
  outline: 0 0 0 0  !important;
  box-shadow: 0 0 0 0 !important;

}

.login_btn {
  color: black;
  background-color: #FFC312;
  width: 100px;
  border-width: 2px;
}

.login_btn:hover {
  border-color: white;
}
</style>
